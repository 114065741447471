import { type ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { segmentTrack } from "util/segment";
import { usePermissions } from "common/core/current_user_role";
import { useActiveOrganization } from "common/account/active_organization";
import { Feature } from "graphql_globals";
import { SEGMENT_EVENTS } from "constants/analytics";
import Svg from "common/core/svg";
import ProofTitlePortalLight from "assets/images/logos/portal-logos/proof-title-light.svg";
import { useViewer } from "util/viewer_wrapper";
import { OnboardingWrapper, useOnboardingSteps } from "common/onboarding";
import { TITLE_IHN_STEPS, TITLE_ODN_STEPS, STEPS } from "common/onboarding/steps";
import { PaymentStep } from "common/onboarding/steps/payment";
import { InviteCoworkerStep } from "common/onboarding/steps/invite_coworker";
import AlertMessage from "common/core/alert_message";
import { getCookie } from "util/cookie";

import type { TitleRouterViewer_viewer as TitleRouterViewer } from "../../router/viewer_query.graphql";
import { EligibilityStep } from "./steps/eligibility";
import TitleAccountOnboardingQuery from "./index.graphql";
import { NotaryStep } from "./steps/notary";
import { ACTIVATION_REFERRING_ORG_COOKIE } from "../referral_activation";

type WrapperProps = {
  children?: ReactElement;
};

const MESSAGES = defineMessages({
  portalLogoAlt: {
    id: "7235b63f-b177-4685-a13b-d7b89bd1cad7",
    defaultMessage: "Proof for title",
  },
});

export function TitleAccountOnboarding() {
  const { viewer, refetch } = useViewer<TitleRouterViewer>();
  const organization = viewer.user?.organization;
  const intl = useIntl();
  const referringOrgName = getCookie(ACTIVATION_REFERRING_ORG_COOKIE);

  const [activeOrganizationId] = useActiveOrganization();

  const { loading, data } = useQuery(TitleAccountOnboardingQuery, {
    variables: {
      organizationId: activeOrganizationId!,
    },
  });

  const steps = organization?.featureList.includes(Feature.ORGANIZATION_NOTARIES)
    ? TITLE_IHN_STEPS
    : TITLE_ODN_STEPS;

  const { currentStep, stepSvg, onNext } = useOnboardingSteps(steps, refetch);

  const renderCurrentStep = () => {
    if (!data || loading) {
      return <LoadingIndicator />;
    }

    if (data.organization?.__typename !== "Organization") {
      throw new Error(`Expected organization, got ${data.organization?.__typename}.`);
    }

    const { organization, viewer } = data;
    const { usStates: viewerStates, publicTitleUnderwriters, notaryUsStates, user } = viewer;
    const {
      id,
      usStates: orgStates,
      publicTitleAgency,
      defaultPayer,
      paymentSpecified,
      defaultPaymentSource,
      assignableRoles,
      featureList,
    } = organization;

    const isIhnEnabled = featureList.includes(Feature.ORGANIZATION_NOTARIES);

    switch (currentStep) {
      case STEPS.ELIGIBILITY:
        return (
          <EligibilityStep
            organizationId={id}
            usStateOptions={viewerStates}
            publicTitleUnderwriterOptions={publicTitleUnderwriters}
            usStatesFormData={orgStates}
            titleUnderwritersFormData={publicTitleAgency?.titleUnderwriters}
            onNext={onNext}
          />
        );
      case STEPS.PAYMENT:
        return (
          <PaymentStep
            portal="title"
            organizationId={id}
            onNext={onNext}
            defaultPayerFormData={paymentSpecified ? defaultPayer : null}
            defaultPaymentSourceFormData={defaultPaymentSource}
            alert={
              Boolean(referringOrgName) && (
                <AlertMessage kind="info">
                  <FormattedMessage
                    id="799f4d3d-316d-409c-8c84-3a3ac5b9a89c"
                    defaultMessage="You've signed up via a referral from {referringOrgName}. {referringOrgName} will cover the cost of transactions that utilize their sponsored Identify Easylink. The payment method you provide here will apply to other transactions that you may perform on Proof."
                    values={{ referringOrgName }}
                  />
                </AlertMessage>
              )
            }
          />
        );
      case STEPS.IHN:
        return (
          <NotaryStep
            organizationId={id}
            onNext={onNext}
            notaryUsStates={notaryUsStates}
            roles={assignableRoles}
            userEmail={user?.email}
          />
        );
      case STEPS.INVITE:
        return (
          <InviteCoworkerStep
            portal="title"
            organizationId={id}
            onNext={onNext}
            onSkip={() =>
              segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_INVITE_ONBOARDING_SUCCEEDED, {
                skipped: true,
              })
            }
            notaryUsStates={notaryUsStates}
            roles={assignableRoles}
            isIhnEnabled={isIhnEnabled}
          />
        );
      default:
        return null;
    }
  };

  return (
    <OnboardingWrapper
      logo={<Svg src={ProofTitlePortalLight} alt={intl.formatMessage(MESSAGES.portalLogoAlt)} />}
      image={stepSvg}
      content={renderCurrentStep()}
      currentStep={currentStep}
      steps={steps}
    />
  );
}

export function TitleAccountOnboardingWrapper(props: WrapperProps) {
  const { children } = props;
  const { hasPermissionFor } = usePermissions();
  const { viewer } = useViewer<TitleRouterViewer>();
  const isSetup = viewer.user?.organization?.isSetup.complete;

  const redirectToOnboarding = !isSetup && hasPermissionFor("editOrganizationDetails");

  if (redirectToOnboarding) {
    return <Navigate to="/onboarding" />;
  }

  if (children) {
    return children;
  }

  return <Outlet />;
}
