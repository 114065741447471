import { useParams, Navigate } from "react-router-dom";

import LoadingIndicator from "common/core/loading_indicator";
import TitleTransactionEditQuery from "title_portal/transactions/graphql/queries/title_edit_query.graphql";
import { QueryWithLoading } from "util/graphql/query";
import { transactionEditRouteV3, transactionDetailsRoute } from "util/routes";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import { useActiveOrganization } from "common/account/active_organization";
import { TransactionErrorRedirect } from "common/transaction_creation/v3/form";

function V3Redirect(props) {
  return <Navigate replace to={transactionEditRouteV3(props.transactionId)} />;
}

function TransactionDetailsRedirect(props) {
  const {
    transaction: { editable, pointsOfContact, transactionType, id },
    viewer: { user },
  } = props.data;

  if (!editable) {
    let route = transactionDetailsRoute(id);

    const isPointOfContactOnTransaction = pointsOfContact.some((poc) => user.id === poc.userId);
    if (isHybridTransactionType(transactionType) && isPointOfContactOnTransaction) {
      route = `/access/transaction/${id}`;
    }

    return <Navigate replace to={route} />;
  }

  return props.children;
}

function TransactionContainer() {
  const [activeOrgId] = useActiveOrganization();
  const { transactionId } = useParams();

  return (
    <QueryWithLoading
      query={TitleTransactionEditQuery}
      variables={{
        transactionId,
        organizationId: activeOrgId,
      }}
    >
      {({ data, loading, error }) => {
        // QueryWithLoading only loads for the first load and so we need this to catch subsequent loads with a different transaction id
        if (loading) {
          return <LoadingIndicator />;
        }

        if (!data?.transaction) {
          return <TransactionErrorRedirect error={error} />;
        }

        return (
          <TransactionDetailsRedirect data={data}>
            <V3Redirect transactionId={transactionId} user={data.viewer.user}></V3Redirect>
          </TransactionDetailsRedirect>
        );
      }}
    </QueryWithLoading>
  );
}

export default TransactionContainer;
